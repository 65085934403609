import {
  httpAppcorp,
  httpSchools,
  httpAppmembers,
  httpMetaDatas,
  httpChannelList,
  httpAllBranchMember,
  httpAppCorpCampus,
  httpCategoryList,
} from "@/api/common";
import { httpGetPayChannels } from "@/api/finance";

import { getStorage } from "@/utils/storage";

export const httpCascaderMembers = async (id, level) => {
  let options = [];
  let path = httpAppcorp;
  try {
    if (level === 1) {
      path = httpSchools;
    } else if (level === 2) {
      path = httpAppmembers;
    }
    const res = await path({ id });
    const list = res?.data;
    if (list) {
      options = list.map((entry) => ({
        value: level === 2 ? entry.id : entry.deptId,
        label: entry.name,
        level: level + 1,
      }));
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取性别、年级、学员类型
export const httpDicts = async (name, region = "customer") => {
  let options = {};
  try {
    const res = await httpMetaDatas({ region, name });
    if (res?.success) {
      const opt = res.data.optionValueV2?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
          children: el.children?.map((ite) => {
            return {
              ...ite,
              label: ite.name,
              value: ite.id,
              text: ite.name,
              key: ite.id,
            };
          }),
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取性别、年级、学员类型
export const httpChannels = async () => {
  let options = {};
  try {
    const res = await httpChannelList();
    if (res?.success) {
      const opt = res.data?.map((el) => {
        return {
          ...el,
          label: el.title,
          value: el.id,
          text: el.title,
          key: el.id,
          children: el?.channelVOs
            ? el.channelVOs.map((it) => {
                return {
                  ...it,
                  label: it.title,
                  value: it.id,
                };
              })
            : [],
        };
      });
      options = {
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

const unique3 = (arr) => {
  const newArray = arr.filter((item, index) => {
    return arr.findIndex((f) => f.id === item.id) === index;
  });
  return newArray;
};

const getPhoneOrderName = (order) => {
  return { 1: "一接", 2: "二接", 3: "三接", 9: "兜底" }[order] || "";
};

/**
 * 查询全量分公司、校区、人员
 * @returns filtered 移除没有人员的分公司 校区，source 全量数据
 */
export const getAllBranchMember = async () => {
  let options = {};
  try {
    const res = await httpAllBranchMember();
    const filtered = (res.data || [])
      .map((m) => {
        return {
          ...m,
          label: m.name,
          title: m.name,
          value: m.deptId,
          allotCorp: m.name,
          allotCorpId: m.deptId,
          completeName: `${m.name}`,
          children: (m.children || [])
            .filter((f) => (f.appMembers || []).length)
            .map((campus) => {
              return {
                ...campus,
                label: campus.name,
                title: campus.name,
                value: campus.deptId,
                allotCorp: m.name,
                allotCorpId: m.deptId,
                allotCampus: campus.name,
                allotCampusId: campus.deptId,
                completeName: `${m.name}-${campus.name}`,
                children: unique3(campus.appMembers || []).map((member) => {
                  const callPhone = `${member.counselorPhone || ""} ${
                    member.landlinePhone || ""
                  }`;
                  return {
                    ...member,
                    // label: `${member.name}  ${getPhoneOrderName(
                    //   member.phoneOrder
                    // )}  ${callPhone}`,
                    label: member.name,
                    title: member.name,
                    value: member.id,
                    allotCorp: m.name,
                    allotCorpId: m.deptId,
                    allotCampus: campus.name,
                    allotCampusId: campus.deptId,
                    allotName: member.name,
                    allotId: member.id,
                    callPhone,
                    completeName: `${campus.name}-${member.name}`,
                  };
                }),
              };
            }),
        };
      })
      .filter((f) => (f.children || []).length);

    const source = (res.data || []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m.deptId,
        children: (m.children || []).map((campus) => {
          return {
            ...campus,
            label: campus.name,
            value: campus.deptId,
            children: undefined,
          };
        }),
      };
    });
    options = {
      pickerOptions: {
        filtered,
        source,
      },
    };
  } catch (error) {
    console.log(error);
  }
  return options;
};

/*
 * 查询当前人所在分公司、校区
 */
export const postAllCampus = async () => {
  try {
    const data = [+getStorage("appMemberId")];
    const res = await httpAppCorpCampus({ data });
    const sourceData = [];
    data.forEach((item) => {
      const itemData = res?.data?.[item] || [];
      sourceData.push(...itemData);
    });
    const source = (sourceData || []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m.deptId,
        completeName: `${m.name}`,
        corpId: m.deptId,
        corpName: m.name,
        allotCorp: m.name,
        allotCorpId: m.deptId,
        children: (m.children || []).map((campus) => {
          delete campus.children;
          return {
            ...campus,
            label: campus.name,
            value: campus.deptId,
            corpId: m.deptId,
            corpName: m.name,
            allotCorp: m.name,
            allotCorpId: m.deptId,
            allotCampus: campus.name,
            allotCampusId: campus.deptId,
            completeName: `${m.name}-${campus.name}`,
          };
        }),
      };
    });
    return source;
  } catch (error) {
    return [];
  }
  // return new Promise((resolve, reject) => {
  //   request
  //     .post(`/api/v1.0/app/${appId}/app-corp-campus`, {
  //       data,
  //     })
  //     .then((res) => {

  //     });
  // });
};

// 商品分类数据处理
export const httpGetCategoryList = async (payload) => {
  let options = [];
  try {
    const res = await httpCategoryList({
      pageSize: 99,
      pageIndex: 1,
      ...payload,
    });
    if (res?.success) {
      options = res.data?.map((el) => {
        const { children, name, id, ...rest } = el;
        const childrenList = children?.map((ite) => {
          delete ite.children;
          return {
            ...ite,
            label: ite?.name,
            value: ite?.id,
            text: ite?.name,
            key: ite?.id,
          };
        });
        return {
          ...rest,
          label: name,
          value: id,
          text: name,
          key: id,
          name,
          id,
          children: childrenList || [],
        };
      });
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取元数据 ??? -> 接口返回的数据一级和二级的id一样，组件会报错，暂时先用拼接key来解决
export const httpDictsKey = async (name, region = "customer") => {
  let options = {};
  try {
    const res = await httpMetaDatas({ region, name });
    if (res?.success) {
      const opt = res.data.optionValueV2?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
          children: el.children?.map((ite) => {
            return {
              ...ite,
              label: ite.name,
              value: `${el.id}-${ite.id}`,
              text: ite.name,
              key: `${el.id}-${ite.id}`,
            };
          }),
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

export const httpPayChannels = async (payload) => {
  // console.log(payload, 'payload');
  let options = [];
  try {
    const res = await httpGetPayChannels({
      ...payload,
    });
    // const res = {
    //   success: true,
    //   data: [
    //     {
    //       id: 3,
    //       name: '聚合支付',
    //       // status: 0,
    //     },
    //     {
    //       id: 5,
    //       name: '你好 POS',
    //     },
    //     {
    //       id: 4,
    //       name: '现金',
    //     },
    //   ],
    // };
    if (res?.success) {
      // console.log(res, 'rrrr');
      options = res.data?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.type,
          text: el.name,
          key: el.type,
          id: el.type,
          name: el.name,
          disabled: el.status === 0,
        };
      });
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};
