import { useRef, useEffect } from "react";
import CustomTabBar from "./tabbar";
import { useLocation } from "react-router-dom";
import { getStorage, setStorage, setLoginInfo } from "@/utils/storage";
import { getUrlParams, wxAuthority, isMobile } from "@/utils";
import { observer } from "mobx-react";
import { useStore } from "@/hooks/useStore";
import styles from "./index.module.scss";
import { SafeArea } from "antd-mobile";

const Layout = (props) => {
  const appContainerRef = useRef(null);
  const { tabbar, children, title, needLogin = true, safeArea } = props;
  const { pathname } = useLocation();
  const willMount = useRef(false);
  let token = getStorage("token");
  const appID = getStorage('xd_appid');
  const userId = getStorage('userId');

  const { authStore, roleStore } = useStore();

  useEffect(() => {
    document.title = title;
    token = getStorage("token");
  }, [authStore?.tokens]);

  if (!willMount.current) {
    willMount.current = true;
    const urlParams = getUrlParams();
    const { code, appId } = urlParams;
    if (appId) {
      // setStorage("xd_appid");
      const businessList = JSON.parse(getStorage("businessList") || "[]");
      // eslint-disable-next-line
      const business = businessList.find((item) => item.appId == appId);
      if (business) setLoginInfo(business);
    }

    if (needLogin) {
      if (!token && !code) {
        authStore
          .info()
          .then((res) => {
            if (res?.thirdpartCorpId && res?.appId) {
              setStorage("agentId", res?.appId);
              setStorage("wxCorpId", res?.thirdpartCorpId);
              wxAuthority({
                wxCorpId: res?.thirdpartCorpId,
                agentId: res?.appId,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (!token && code) {
        authStore.login(code);
      }
    }
  }

  useEffect(() => {
    if (appID && userId) {
      roleStore.httpPermissions(appID, userId)
    }
  }, [appID, userId]);

  return (
    <>
      {needLogin && !token ? null : (
        <div className={styles.layout} ref={appContainerRef}>
          <div className={styles.content}>{children}</div>
          {tabbar ? <CustomTabBar pathname={pathname} /> : null}
          <SafeArea position="bottom" style={{ background: "#fff" }} />

          {/* {tabbar && safeArea && isMobile() ? (
            <div className={styles.safeArea} />
          ) : null} */}
        </div>
      )}
    </>
  );
};

export default observer(Layout);
