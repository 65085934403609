import request from "@/utils/request";

// 根据校区查询商品分页信息
export const httpCourseGoodsListByDept = (payload) => {
  // return request.post(`/api/v1.0/app/{app-id}/goodsPage4Order`, {
  return request.post(`/api/v1.0/app/{app-id}/goods/page4Order`, {
    params: {
      ...payload,
    },
  });
};

/**
 *  优惠券列表
 * @param {*} couponType
 * @returns
 */
export const httpCouponList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/range/order`, {
    params: {
      ...payload,
    },
  });
};
/**
 *  学员优惠券列表
 * @param {*} studentId  学员id
 * @param {*} campusId   校区id
 * @returns
 */
export const httpStudentCouponList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/coupon/student`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 优惠券，商品  算价接口
 * @param {*} campusId  校区id
 * @param {*} studentId  学员id
 * @param {number[]} couponId  优惠券id
 * @param {array<{goodsId, quantity}>} orderGoodsQryList 商品信息
 * @returns {*}
 */

export const httpCalculateCoupon = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/pricing-platform/calculate/coupon`,
    {
      params: {
        ...payload,
      },
    }
  );
};

/**
 * 获取学员账户余额
 * @param payload
 */
export const getStudentAmount = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/payment/getStudentBalance`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 根据校区分公司查询支付渠道
 * @param {*} campusId
 * @returns
 */
export const httpGetPayChannels = ({ corpId, campusId }) => {
  return request.get(
    `/api/v1.0/app/{app-id}/corp/${corpId}/campus/${campusId}/pay-channels`
  );
};

// 创建订单
export const httpOrderCreate = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/order-center/order`, {
    params: {
      ...payload,
    },
  });
};

/** 支付-获取二维码
 * @param {string} orderNo  订单号
 * @param {string} payType  支付方式
 * @param {string} payAmount  支付金额
 * @param {Number} orderType 单据类型，1-订购单，2-缴费单
 */
export const httpCodePays = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/pay`, {
    params: {
      ...payload,
    },
  });
};

/** pos支付-获取流水号
 * @param {string} orderNo  订单号
 * @param {string} payAmount  支付金额
 *
 */
export const httpPosPays = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/pay-center/pos/pay`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 余额支付
 * @param {Object} payload
 * @param {String} payload.orderNo 订单号
 * @param {Number} payload.actualPaymentAmount 支付金额
 */
export const httpBalancePay = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/payment/payByBalance`, {
    params: {
      ...payload,
    },
  });
};

// 父订单详情
export const httpOrderDetail = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/order-center/order/${id}`);
};

/**
 * 获取缴费单的支付状态
 */
export const getPayStatus = (detailNo) => {
  return request.get(`/api/v1.0/app/{app-id}/payment-detail/${detailNo}`);
};
