import React from "react";
import authStore from "./auth";
import testStore from "./test";
import dictsStore from "./dicts";
import studentDictsStore from "./studentDicts";
import appStore from "./app";
import commodityCenterStore from "./commodityCenter";
import roleStore from "./role";

const StoresContext = React.createContext({
  testStore,
  authStore,
  dictsStore,
  studentDictsStore,
  appStore,
  commodityCenterStore,
  roleStore,
});

export default StoresContext;
