const routes = [
  {
    path: "/detail/:id",
    component: () => import("@/views/detail"),
    key: "/detail",
    needLogin: true,
    tabbar: false,
    title: "客户详情",
    safeArea: false,
  },
  {
    path: "/",
    component: () => import("@/views/All"),
    key: "/all",
    needLogin: true,
    tabbar: true,
    title: "菱镜",
    safeArea: true,
  },
  {
    path: "/home",
    component: () => import("@/views/home"),
    key: "/home",
    needLogin: true,
    tabbar: false,
    title: "客户管理",
    cache: true,
    safeArea: true,
  },
  {
    path: "/cluelist",
    component: () => import("@/views/ClueList"),
    key: "/cluelist",
    needLogin: true,
    tabbar: false,
    title: "线索管理",
    cache: true,
    safeArea: true,
  },
  {
    path: "/cluedetail/:id",
    component: () => import("@/views/ClueDetail"),
    key: "/cluedetail",
    needLogin: true,
    tabbar: false,
    title: "线索详情",
    safeArea: false,
  },
  {
    path: "/mine",
    component: () => import("@/views/mine"),
    key: "/mine",
    needLogin: true,
    tabbar: true,
    title: "我的",
    safeArea: true,
  },
  {
    path: "/student",
    component: () => import("@/views/StudentList"),
    key: "/student",
    needLogin: true,
    tabbar: false,
    title: "学员管理",
    safeArea: true,
  },
  {
    path: "/studetail/:id",
    component: () => import("@/views/StudentDetail"),
    key: "/studetail",
    needLogin: true,
    title: "学员详情",
    safeArea: true,
  },
  {
    path: "/stuinfo/:id",
    component: () => import("@/views/StudentInfo"),
    key: "/stuinfo",
    needLogin: true,
    title: "学员基本信息",
  },
  {
    path: "/students/create",
    component: () => import("@/views/Students"),
    key: "/studentsCreate",
    needLogin: true,
    tabbar: false,
    title: "创建学员",
  },
  {
    path: "/students/records/:id/:id1/:id2/:name",
    component: () => import("@/views/StudentsRecords"),
    key: "/studentsRecords",
    needLogin: true,
    tabbar: false,
    title: "成长档案",
  },
  {
    path: "/students/recordsDetail/:id/:id1/:id2/:name",
    component: () => import("@/views/StudentsRecordsDetail"),
    key: "/studentsRecordsDetail",
    needLogin: false,
    tabbar: false,
    title: "档案详情",
  },
  {
    path: "/students/sharePoster/:id/:id1/:id2/:name",
    component: () => import("@/views/StudentsSharePoster"),
    key: "/studentsSharePoster",
    needLogin: true,
    tabbar: false,
    title: "海报分享",
  },
  {
    path: "/editMine",
    component: () => import("@/views/editMine"),
    key: "/editMine",
    needLogin: true,
    tabbar: true,
    title: "菱镜",
    safeArea: true,
  },
  {
    path: "/addcus",
    component: () => import("@/views/AddCustomer"),
    key: "/addCustomer",
    needLogin: true,
    title: "新增客户",
    safeArea: true,
  },
  {
    path: "/addclue",
    component: () => import("@/views/AddClue"),
    key: "/AddClue",
    needLogin: true,
    title: "新增线索",
    safeArea: true,
  },
  {
    path: "/behavior",
    component: () => import("@/views/Behavior"),
    key: "/Behavior",
    needLogin: true,
    title: "跟进记录",
    safeArea: true,
  },
  {
    path: "/test",
    title: "菱镜",
    component: () => import("@/views/testPage"),
    needLogin: false,
  },
  {
    path: "/login",
    title: "菱镜",
    component: () => import("@/views/Login"),
    needLogin: false,
  },
  {
    path: "/qiwei",
    title: "菱镜",
    component: () => import("@/views/qiwei"),
    needLogin: true,
  },
  {
    path: "/callRecords",
    title: "通话记录",
    component: () => import("@/views/CallRecords"),
    needLogin: true,
  },
  // {
  //   path: "/auth",
  //   title: "登录",
  //   component: () => import("@/views/auth"),
  // },
  {
    path: "/",
    redirect: "/",
  },
];

export default routes;
