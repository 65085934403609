import request from "@/utils/request";

/**
 * 查询登录人的角色权限
 * @param appId
 * @param userId
 */
export const getMemberRolePermissions = (appId, userId) => {
  return request.get(`api/v1.0/app/${appId}/app-member-rps`, {
    params: {
      userId,
    },
  })
};