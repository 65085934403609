import { makeAutoObservable } from "mobx";
import "./config";
import {
  httpDicts,
  httpGetCategoryList,
  httpDictsKey,
  httpPayChannels,
} from "@/utils/dict";

// 很多锁
const locks = {};

class CommodityCenter {
  // 类型
  applicableGrade = []; // 年级

  goodsCategory = []; // 商品类目（SPU）

  payChannels = []; // 支付方式

  constructor() {
    makeAutoObservable(this);
  }

  async getDicts(payload = {}, rootState) {
    const { type, ...rest } = payload;
    const dicts = rootState?.commodityCenter?.[type];
    // 课程类目 不在状态管理缓存
    if (locks[type] || (dicts?.length > 0 && type !== "goodsCategory")) return;
    locks[type] = true;
    if (type === "goodsCategory") {
      const res = await httpGetCategoryList({ ...rest });
      this[type] = res;
      locks[type] = false;
    } else if (type === "applicableProduct") {
      const res = await httpDictsKey(type, "goods_course");
      this[type] = res.pickerOptions;
      locks[type] = false;
    } else {
      const res = await httpDicts(type, "goods_course");
      this[type] = res.pickerOptions;
      locks[type] = false;
    }
  }

  async getPayChannelsDicts(payload = {}, rootState) {
    const { type, ...rest } = payload;
    if (type === "payChannels") {
      const res = await httpPayChannels({ ...rest });
      // console.log(res, 'rrr');
      this[type] = res;
      locks[type] = false;
    }
  }
}

export default new CommodityCenter();
