const routes = [
  {
    path: "/corder",
    component: () => import("@/views/OrderCenter/Create"),
    key: "/corder",
    needLogin: true,
    tabbar: false,
    title: "订购课程",
    safeArea: true,
  },
];

export default routes;
