import {makeAutoObservable} from "mobx";
import "./config";
import {getMemberRolePermissions} from "@/api/role";
import {setStorage} from "@/utils/storage";

class RoleStore {
  permissions = [];

  constructor() {
    makeAutoObservable(this);
  }

  async httpPermissions(appId, userId) {
    if (this.permissions?.length) return;
    const res = await getMemberRolePermissions(appId, userId);
    const result = res?.data?.resources || [];
    this.permissions = result;
    setStorage('permissions_resources', JSON.stringify(result));
  };
}

export default new RoleStore();